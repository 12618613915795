


































































































































import { mapGetters } from 'vuex';
import { DataTable, Form } from '../components/admin';
import { AdminFormMixin, NotificationMixin } from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

@Component({
  name: 'Admins',
  components: {
    DataTable,
    Form,
  },
  created() {
    const params = {
      perPage: 1000,
    };
    this.$store.dispatch('client/list', { key: 'dropdown', params });
    this.$store.dispatch('user/getRoles', { params });
  },
  computed: {
    ...mapGetters('user', ['getList', 'getCount']),
  },
})
export default class Admins extends Mixins(AdminFormMixin, NotificationMixin) {
  selected: any = null;
  fields = [
    {
      key: 'client.name',
      label: 'Client Access',
      sortable: true,
    },
    {
      key: 'client.code',
      label: 'Client Code',
      sortable: true,
    },
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'username',
      label: 'Email',
      sortable: true,
    },
  ];

  readonly $refs!: {
    addModal: BModal;
    editModal: BModal;
    resetModal: BModal;
    deleteModal: BModal;
    table: DataTable;
  };

  fetchData(params = {}) {
    this.$store.dispatch('user/list', { params });
    this.$store.dispatch('user/count', { params });
  }

  addAdmin() {
    this.selected = {};
    this.$refs.addModal.show();
  }

  editAdmin(admin) {
    this.selected = Object.assign({}, admin);
    // TODO: update select to support case like this i.e. value is array of objects
    // this.selected.clients = admin.clients.map((client) => client.id)
    this.$refs.editModal.show();
  }

  deleteAdmin(admin) {
    this.selected = admin;
    this.$refs.deleteModal.show();
  }

  resetPassword(admin) {
    this.selected = admin;
    this.$refs.resetModal.show();
  }

  handleAdd(data) {
    return this.$store
      .dispatch('user/create', { data })
      .then(() => {
        this.$refs.addModal.hide();
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to create user';
        this.notifyError(errMessage);
      });
  }

  handleEdit(data) {
    const { id } = this.selected;
    return this.$store
      .dispatch('user/update', { id, data })
      .then(() => {
        this.$refs.editModal.hide();
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to update user';
        this.notifyError(errMessage);
      });
  }

  handleDelete(evt) {
    const { id } = this.selected;
    return this.$store
      .dispatch('user/delete', { id })
      .then(() => {
        this.$refs.deleteModal.hide();
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to delete user';
        this.notifyError(errMessage);
      });
  }

  handleReset(evt) {
    const { username } = this.selected;
    return this.$store
      .dispatch('auth/forgot', { username })
      .then(() => {
        this.$refs.resetModal.hide();
      })
      .catch((err) => {
        const errMessage = err.message || 'Failed to update user';
        this.notifyError(errMessage);
      });
  }

  onCancel() {
    this.selected = null;
    this.$refs.addModal.hide();
    this.$refs.editModal.hide();
  }
}
